import classNames from "classnames";
import PropTypes from "prop-types";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import CurrencyInput from "react-currency-input-field";
import { Col, FormFeedback, FormGroup, Label } from "reactstrap";
import { handleFocus, formatarValor } from "../coreUtils";
import { HintLabel } from "./HintLabel";

const NumberInput = forwardRef(
  (
    {
      required = false,
      onChange,
      md,
      mdInput = 12,
      label,
      style,
      disabled = false,
      defaultValue,
      value,
      placeholder = "",
      clearOnDisable = false,
      isPercentage = false,
      autoFocus,
      tabOrder,
      onBlur,
      table,
      decimalPlaces = 2,
      allowNegativeValue = false,
      id,
      divClassName,
      align = "right",
      disableGroupSeparators = true,
      disabledBackground,
      hint,
    },
    ref
  ) => {
    const [inputValue, setInputValue] = useState(defaultValue ?? null);
    const [invalid, setInvalid] = useState(false);
    const [onFocus, setOnFocus] = useState(false);
    const [internalId] = useState(
      id ?? "ni-" + Math.floor(Math.random() * Date.now())
    );
    const inputRef = useRef();

    const preparaValor = (v) =>
      typeof v === "string"
        ? parseFloat((v || "0.0").replace(".", "").replace(",", "."))
        : v;

    useEffect(() => {
      if (defaultValue) setInputValue(defaultValue);
    }, []);

    useEffect(() => {
      if (value !== preparaValor(inputValue) && !onFocus) {
        setInputValue(value);
      }
    }, [value, onFocus]);

    useEffect(() => {
      if (disabled && clearOnDisable) {
        handleOnChange(0);
      }
    }, [disabled, clearOnDisable]);

    const handleOnChange = (v) => {
      setInputValue(v);
      setInvalid(false);
      if (onChange) onChange(preparaValor(v ?? 0));
    };

    const onFocusInternal = (e) => {
      setOnFocus(true);
      e.target.select();
    };

    const onBlurInternal = (e) => {
      setOnFocus(false);
      if (onBlur) onBlur(e, preparaValor(e.target.value ?? 0));
    };

    const onKeyDown = (e) => {
      if (!table) {
        handleFocus(e);
      } else if (e.key === "Enter") {
        onBlurInternal(e);
      }
    };

    useImperativeHandle(ref, () => ({
      floatValue: () => preparaValor(inputValue),
      clear: () => {
        setInputValue(null);
      },
      focus: () => inputRef.current.focus(),
    }));

    return (
      <Col
        md={md}
        className={classNames(table ? "px-0" : undefined, divClassName)}
      >
        <FormGroup className={table ? "mb-0" : undefined}>
          {label && (
            <HintLabel hintText={hint} for={internalId} label={label} />
          )}
          <Col md={mdInput} className="no-gutters">
            <CurrencyInput
              className={
                `text-${align} form-control` + (invalid ? " is-invalid" : "")
              }
              style={
                disabledBackground
                  ? { ...style, backgroundColor: disabledBackground }
                  : style
              }
              onValueChange={handleOnChange}
              onBlur={onBlurInternal}
              onFocus={onFocusInternal}
              value={inputValue}
              invalid={invalid}
              required={required}
              disabled={disabled}
              defaultValue={defaultValue}
              decimalsLimit={decimalPlaces}
              suffix={isPercentage ? " %" : ""}
              decimalScale={decimalPlaces}
              disableAbbreviations
              disableGroupSeparators={disableGroupSeparators}
              allowNegativeValue={allowNegativeValue}
              placeholder={placeholder}
              autoFocus={autoFocus || table}
              onKeyDown={onKeyDown}
              tabIndex={tabOrder}
              ref={inputRef}
              id={internalId}
            />
          </Col>
          <FormFeedback valid={false}>Este campo é obrigatório</FormFeedback>
        </FormGroup>
      </Col>
    );
  }
);

NumberInput.propTypes = {
  required: PropTypes.bool,
  onChange: PropTypes.func,
  md: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
  style: PropTypes.object,
  disabled: PropTypes.bool,
};

export default NumberInput;
