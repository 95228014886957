export const tiposMaterial = [
  { label: "MDF Plus HD 30mm", value: "MDF" },
  { label: "PVC", value: "PVC" },
];

export const tiposMaterialEconomico = [
  { label: "MDF Plus HD 30mm", value: "MDF" },
  {
    label: "MDF Plus 25mm",
    value: "M25",
  },
  { label: "PVC", value: "PVC" },
];
