import React, { useState, useEffect } from "react";
import {
  LinkButton,
  NumberInput,
  PageContainer,
  RadioGroup,
  TextInput,
} from "../../../components";
import { PortasPedidoGrid } from "./components/PortasPedidoGrid";
import { Card, Row, Spinner } from "reactstrap";
import { useLocation } from "react-router-dom";
import { apiGet, apiPost, apiDelete, apiPut } from "../../../api";
import { useHistory } from "react-router-dom";
import IncluirKitPortasAbrir from "./components/IncluirKitPortasAbrir";
import {
  tiposMaterial,
  tiposMaterialEconomico,
} from "../../../utils/materiais";

export const IncluirKitPortaAbrir = () => {
  const location = useLocation();
  const [idPedidoCab, setIdPedidoCab] = useState(null);
  const [descricaoPed, setDescricaoPed] = useState(null);
  const [loadingParams, setLoadingParams] = useState(true);
  const [tipoMaterial, setTipoMaterial] = useState(tiposMaterial[0].value);
  const [portasPedido, setPortasPedido] = useState([]);
  const [markup, setMarkup] = useState(0);
  const [totalPedido, setTotalPedido] = useState(0);
  const [params, setParams] = useState(null);
  const [recalcularMarkup, setRecalcularMarkup] = useState(false);
  const [tiposMaterialLiberados, setTiposMaterialLiberados] =
    useState(tiposMaterial);
  const history = useHistory();

  const buscarDadosPedido = async (idPedido) => {
    const ret = await apiGet(`pedido/buscar_dados/${idPedido}/`);
    if (ret) {
      setDescricaoPed(ret.descricao_ped);
      setMarkup(ret.markup_ped);
      setPortasPedido(ret.itens);
      setTotalPedido(parseFloat(ret.vlr_total));
    }
  };

  const buscarItensPedido = async (idPedido) => {
    const ret = await apiGet(`pedido/listar_itens/${idPedido}/`);
    if (ret) {
      setTotalPedido(ret.tot_pedido);
      setPortasPedido(ret.itens);
    }
  };

  const buscarDados = async () => {
    await buscarParametros();
    if (location.state?.idPedidoAlterar) {
      // Buscar dados do pedido para alteração
      setIdPedidoCab(location.state.idPedidoAlterar);
      await buscarDadosPedido(location.state.idPedidoAlterar);
      await buscarItensPedido(location.state.idPedidoAlterar);
    }
  };

  const buscarParametros = async () => {
    const ret = await apiGet("pedido/buscar_params/");

    if (ret) {
      setParams(ret);
      setMarkup(ret.markup_pad);
      if (ret.oferecer_kit_economico === true) {
        // Ajusta a descrição do MDF 25mm para adicionar a descrição Econômico
        // abaixo do item
        setTiposMaterialLiberados(
          tiposMaterialEconomico.map((e) => {
            if (e.value === "M25") {
              e.label = (
                <div className="d-flex" style={{ flexDirection: "column" }}>
                  MDF Plus 25mm
                  <span className="m-auto text-muted">Econômico</span>
                </div>
              );
            }

            return e;
          })
        );
      }
      setLoadingParams(false);
    } else {
      history.goBack();
    }
  };

  useEffect(() => {
    buscarDados();
  }, []);

  const abrirCab = async () => {
    const payload = {
      tipo_pedido: "KABR",
      descricao_ped: descricaoPed ?? "",
      markup_ped: parseFloat(markup),
    };
    const ret = await apiPost("pedido/abrir_cab/", payload, {
      returnDataSuccess: true,
      successMesage: false,
    });

    if (ret?.id_pedido) {
      setIdPedidoCab(ret.id_pedido);
      return ret.id_pedido;
    } else {
      return null;
    }
  };

  const handleDeleteItem = async (idItem) => {
    const ret = await apiDelete(`pedido/excluir_item/${idItem}/`);

    if (ret) {
      buscarItensPedido(idPedidoCab);
    }
  };

  const handleMarkupAlterado = async () => {
    if (recalcularMarkup && idPedidoCab) {
      const ret = await apiPut(`pedido/recalcular_markup/`, {
        id_pedido: idPedidoCab,
        markup_novo: parseFloat(markup),
      });

      if (ret) {
        setRecalcularMarkup(false);
        buscarItensPedido(idPedidoCab);
      }
    }
  };

  return (
    <PageContainer title="Incluir Kit Porta - Abrir" number="0002" canGoBack>
      {loadingParams ? (
        <Spinner
          style={{
            width: "3rem",
            height: "3rem",
            display: "flex",
            margin: "auto",
          }}
        />
      ) : (
        <>
          <Card body>
            <Row>
              <TextInput
                autoFocus
                md={5}
                label="Descrição do Pedido"
                onChange={setDescricaoPed}
                value={descricaoPed}
              />
              <RadioGroup
                label="Tipo de Material"
                options={tiposMaterialLiberados}
                value={tipoMaterial}
                onChange={setTipoMaterial}
              />
              <NumberInput
                label="Markup"
                decimalPlaces={2}
                value={markup}
                onChange={(v) => {
                  if (v != markup) {
                    setRecalcularMarkup(true);
                    setMarkup(v);
                  }
                }}
                onBlur={handleMarkupAlterado}
                isPercentage
                md={1}
              />
              <NumberInput
                md="auto"
                divClassName="ml-auto"
                label="Total do Pedido"
                disabled
                disabledBackground="#c1dec9"
                value={totalPedido}
              />
            </Row>
            <Row className="mt-2">
              <div
                style={{
                  float: "right",
                  marginLeft: "auto",
                  display: "flex",
                  justifyContent: "left",
                }}
              >
                <LinkButton
                  padded={false}
                  color="success"
                  md="auto"
                  pathname="/pedidos/confirmar/"
                  disabled={idPedidoCab == null}
                  state={{ idPedido: idPedidoCab }}
                >
                  Continuar Pedido
                </LinkButton>
              </div>
            </Row>
          </Card>
          <IncluirKitPortasAbrir
            idPedidoCab={idPedidoCab}
            abrirCab={abrirCab}
            params={params}
            tipoMaterial={tipoMaterial}
            numItensPed={portasPedido.length}
            buscarItensPedido={buscarItensPedido}
            markup={markup}
            qtdPortas={portasPedido.length}
          />
          <Card body>
            <PortasPedidoGrid
              data={portasPedido}
              handleDeleteItem={handleDeleteItem}
            />
          </Card>
        </>
      )}
    </PageContainer>
  );
};
