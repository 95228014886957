import Cookies from "js-cookie";
import axios from "axios";
import settings from "../../settings";
import React, { useState } from "react";
import { Eye, EyeOff } from "react-feather";
import { Redirect } from "react-router-dom";
import {
  Button,
  Card,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  UncontrolledAlert,
} from "reactstrap";
import { login } from "../../auth/session";
import { isDevEnv } from "../../coreUtils";
import { kClientId, kClientSecret } from "../../api";

const SignIn = () => {
  const [user, setUser] = useState(Cookies.get("user"));
  const [password, setPassword] = useState("");
  const [loginErrMsg, setLoginErrMsg] = useState("");
  const [loginErrAttempt, setLoginErrAttempt] = useState(0);
  const [loginSuccess, setLoginSuccess] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);

  const nickname = Cookies.get("nickname");

  const fazerLogin = async () => {
    const data = new URLSearchParams({
      grant_type: "password",
      username: user,
      password: password,
    });

    Cookies.remove("access_token");

    await axios
      .create({
        baseURL: isDevEnv() ? settings.API_ADDRESS_DEV : settings.API_ADDRESS,
      })
      .post(`/o/token/`, data, {
        timeout: 5000,
        auth: {
          username: kClientId,
          password: kClientSecret,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          login(response.data.access_token, response.data.refresh_token);
          Cookies.set("nickname", prepareNickname(user));

          setLoginSuccess(true);
        } else {
          loginAttempt(response.data.msg);
        }
      })
      .catch((err) => {
        loginAttempt(
          err.response?.data?.error === "invalid_grant"
            ? "Usuário ou senha incorretos."
            : err.response?.data?.msg ??
                "Falha ao tentar se conectar com o serviço de autenticação."
        );
      });
  };

  const handleClickLogin = async (e) => {
    e.preventDefault();

    setLoginErrMsg("");

    if (user && password) {
      fazerLogin();
    } else {
      loginAttempt("Revise o formulário e tente novamente.");
    }
  };

  /* transforma o username em nickname */
  const prepareNickname = (user) => {
    user = user.split("@")[0];
    const nickname = user.charAt(0).toUpperCase() + user.slice(1);
    return nickname;
  };

  /* quando existe uma falha no login. */
  const loginAttempt = (msg) => {
    const _loginErrAttempt = loginErrAttempt + 1;
    setLoginErrMsg(msg);
    setLoginErrAttempt(_loginErrAttempt);
  };

  if (loginSuccess) {
    return <Redirect to="/" />;
  } else {
    return (
      <React.Fragment>
        <div className="text-center mt-4">
          <h2>Bem vindo {nickname && "de volta, " + nickname}</h2>
          <p className="lead">Faça login na sua conta para continuar</p>
        </div>

        {loginErrMsg.length > 0 && (
          <UncontrolledAlert color="danger">
            <div className="alert-message">{loginErrMsg}</div>
          </UncontrolledAlert>
        )}

        <Card className={isDevEnv() ? "dev-env" : undefined} body>
          <div className="m-sm-4">
            <Form>
              <FormGroup>
                <Label className="label-auth-login">Usuário</Label>
                <Input
                  bsSize="lg"
                  type="email"
                  name="user"
                  placeholder="Nome de usuário"
                  value={user}
                  onChange={(e) => setUser(e.target.value)}
                  required
                  className="input-auth-login"
                />
              </FormGroup>
              <FormGroup>
                <Label className="label-auth-login">Senha</Label>
                <InputGroup>
                  <Input
                    bsSize="lg"
                    type={passwordVisible ? "text" : "password"}
                    name="password"
                    placeholder="Minha senha"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                    className="input-auth-login"
                  />
                  <InputGroupAddon addonType="append">
                    <InputGroupText
                      style={{ cursor: "pointer", backgroundColor: "white" }}
                      onClick={() => setPasswordVisible(!passwordVisible)}
                    >
                      {passwordVisible ? (
                        <Eye size={15} />
                      ) : (
                        <EyeOff size={15} />
                      )}
                    </InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </FormGroup>

              <div className="text-center mt-3">
                <Button
                  type="submit  "
                  color="primary"
                  size="lg"
                  onClick={handleClickLogin}
                >
                  Login
                </Button>
              </div>
            </Form>
          </div>
        </Card>
      </React.Fragment>
    );
  }
};

export default SignIn;
