import React from "react";
import { BsFileText } from "react-icons/bs";
import { UncontrolledTooltip } from "reactstrap";

export const ModalDetalhesIcone = ({ id, toggle, hint = "Ver Detalhes" }) => {
  return (
    <>
      <BsFileText
        style={{ cursor: "pointer" }}
        size={18}
        onClick={() => toggle()}
        id={`det${id}`}
      />
      <UncontrolledTooltip target={`det${id}`}>{hint}</UncontrolledTooltip>
    </>
  );
};
