import React, { useState } from "react";
import { Row } from "reactstrap";
import {
  AsyncComboBox,
  ComboBox,
  FormButton,
  IntegerInput,
  NumberInput,
} from "../../../../../components";
import { apiPost } from "../../../../../api";

const tiposComponentes = [
  { label: "Batente", value: "BATE" },
  { label: "Bobina", value: "BOBI" },
  { label: "Borracha", value: "BORR" },
  { label: "Cantoneira", value: "CANT" },
  { label: "Cola", value: "COLA" },
  { label: "Dobradiça", value: "DOBR" },
  { label: "Espaçador", value: "ESPA" },
  { label: "Fechadura", value: "FECH" },
  { label: "Fita de Borda", value: "FITA" },
  { label: "Kit Caixa de Trilho", value: "KITC" },
  { label: "Rodapé", value: "RODA" },
];

const IncluirOutrosForm = ({ abrirCab, idPedidoCab, buscarItensPedido }) => {
  const [idComponente, setIdComponente] = useState(null);
  const [descricaoComponente, setDescricaoComponente] = useState(null);
  const [vlrUnitComponente, setVlrUnitComponente] = useState(0);
  const [quantidadeComponente, setQuantidadeComponente] = useState(1);
  const [tipoComponente, setTipoComponente] = useState(null);

  const [loadingIncluirComponente, setLoadingIncluirComponente] =
    useState(false);

  const limparIncluirNovoComponente = () => {
    setIdComponente(null);
    setDescricaoComponente(null);
    setVlrUnitComponente(0);
    setQuantidadeComponente(1);
  };

  const incluirComponente = async () => {
    setLoadingIncluirComponente(true);
    try {
      let idPedidoVincularItem;
      if (idPedidoCab == null) {
        const retAbrirCab = await abrirCab();
        if (retAbrirCab) {
          idPedidoVincularItem = retAbrirCab;
        } else {
          return;
        }
      } else {
        idPedidoVincularItem = idPedidoCab;
      }

      const payload = {
        id_pedido: idPedidoVincularItem,
        tipo_componente: tipoComponente,
        id_componente: idComponente,
        descricao_componente: descricaoComponente ?? "",
        quantidade: quantidadeComponente,
        vlr_item: vlrUnitComponente,
        vlr_total: quantidadeComponente * vlrUnitComponente,
        obs: "",
      };

      if (await apiPost("pedido/componentes/incluir/", payload)) {
        limparIncluirNovoComponente();
        buscarItensPedido(idPedidoVincularItem);
      }
    } finally {
      setLoadingIncluirComponente(false);
    }
  };

  const concatModelName = {
    BATE: "batente",
    BOBI: "bobina",
    BORR: "borracha",
    CANT: "cantoneira",
    COLA: "cola",
    DOBR: "dobradica",
    ESPA: "espacador",
    FECH: "fechadura",
    FITA: "fita_borda",
    KITC: "caixa_trilho",
    RODA: "rodape",
  }[tipoComponente];

  const labelComboBox = {
    BATE: "Batente",
    BOBI: "Bobina",
    BORR: "Borracha",
    CANT: "Cantoneira",
    COLA: "Cola",
    DOBR: "Dobradiça",
    ESPA: "Espaçador",
    FECH: "Fechadura",
    FITA: "Fita de Borda",
    KITC: "Kit Caixa de Trilho",
    RODA: "Rodapé",
  }[tipoComponente];

  return (
    <>
      <Row>
        <ComboBox
          label="Tipo de Componente"
          options={tiposComponentes}
          onChange={(s) => {
            setTipoComponente(s);
            if (s !== tipoComponente) {
              setIdComponente(null);
              setDescricaoComponente(null);
              setVlrUnitComponente(0);
            }
          }}
          isClearable={false}
          isSearchable={false}
        />
        <AsyncComboBox
          label={labelComboBox}
          isConcatField
          concatModelName={concatModelName}
          defaultOptions
          onChange={(_, selected) => {
            setIdComponente(selected?.value);
            setDescricaoComponente(selected?.label);
            setVlrUnitComponente(parseFloat(selected?.vlr_preco ?? "0"));
          }}
          md={6}
          disabled={tipoComponente === null}
          className="mr-4"
          isClearable
          value={idComponente}
        />
      </Row>
      <Row>
        <IntegerInput
          md={2}
          label="Quantidade"
          onChange={setQuantidadeComponente}
          value={quantidadeComponente}
        />
        <NumberInput
          md={2}
          label="Valor Unitário"
          disabled
          value={vlrUnitComponente}
        />
        <NumberInput
          md={2}
          label="Total do Item"
          disabled
          value={(quantidadeComponente ?? 0) * (vlrUnitComponente ?? 0)}
        />
        <FormButton
          md="auto"
          loading={loadingIncluirComponente}
          onClick={incluirComponente}
          color="info"
        >
          Incluir Componente
        </FormButton>
      </Row>
    </>
  );
};

export default IncluirOutrosForm;
