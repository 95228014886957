import React from "react";
import { ColorField } from "../../../../components";
import { formatNumber } from "../../../../coreUtils";
import { Table } from "../../../../components/Table";

const statusPedido = [
  {
    value: "DIG",
    color: "#8102a1",
    hint: "Em Digitação",
  },
  {
    value: "AEE",
    color: "#4287f5",
    hint: "Aguardando Envio para Ecoplac",
  },
];

const columns = [
  {
    dataField: "nro_pedido",
    text: "#",
    align: "center",
    headerAlign: "center",
  },
  {
    dataField: "desc_tipo_pedido",
    text: "Tipo de Pedido",
    align: "center",
    headerAlign: "center",
  },
  {
    dataField: "descricao_ped",
    text: "Descrição",
    align: "left",
    headerAlign: "left",
  },
  {
    dataField: "nome_cliente",
    text: "Cliente",
    align: "left",
    headerAlign: "left",
  },
  {
    dataField: "vlr_total_sem_markup",
    text: "Valor Ecoplac",
    align: "right",
    headerAlign: "right",
    formatter: (c) => formatNumber(c, true, 2),
  },
  {
    dataField: "markup_ped",
    text: "Markup",
    align: "right",
    headerAlign: "right",
    formatter: (c) => formatNumber(c, true, 2),
  },
  {
    dataField: "vlr_frete",
    text: "Frete",
    align: "right",
    headerAlign: "right",
    formatter: (c) => formatNumber(c, true, 2),
  },
  {
    dataField: "vlr_total",
    text: "Valor de Venda",
    align: "right",
    headerAlign: "right",
    formatter: (c) => formatNumber(c, true, 2),
  },
  {
    dataField: "status",
    text: "Status",
    align: "center",
    headerAlign: "center",
    formatter: (c) => <ColorField scheme={statusPedido} value={c} />,
  },
];

export const GerenciamentoPedidosGrid = ({ data, setSelected }) => {
  return (
    <Table data={data} columns={columns} onSelect={setSelected} paginated />
  );
};
