import React, { useState } from "react";
import { Col, Label } from "reactstrap";

const FormLabel = ({ md = "auto", children, className, style }) => {
  return (
    <Col md={md} style={style} className={className}>
      <Label className="form-label">{children}</Label>
    </Col>
  );
};

export default FormLabel;
