import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import AsyncSelect from "react-select/async";
import {
  Col,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import { handleFocus } from "../coreUtils";
import ComboBoxService from "../services/components/ComboBoxService";
import { ConcatShortcut } from "./ConcatShortcut";
import ComboBoxOptionWithPhoto from "./ComboBoxOption";
import { HiPhoto } from "react-icons/hi2";
import { UncontrolledTooltip } from "reactstrap/lib";

const AsyncComboBox = forwardRef(
  (
    {
      loadOptions,
      onChange,
      isClearable = false,
      isMulti = false,
      isSearchable = true,
      hideShortcut = false,
      md = 4,
      label,
      placeholder = "Selecione...",
      name,
      className,
      defaultOptions,
      isConcatField,
      concatModelName,
      value,
      options,
      disabled,
      clearOnDisable,
      autoFocus,
      tabOrder,
      onBlur,
      id,
      aditional,
    },
    ref
  ) => {
    const [selectedOption, setSelectOption] = useState({});
    const selectRef = useRef();
    const [loadedOptions, setLoadedOptions] = useState(loadOptions);
    const [internalId] = useState(id ?? Math.floor(Math.random() * Date.now()));
    const [modalIsOpen, setModalIsOpen] = useState(false);

    const toggleModal = () => setModalIsOpen(!modalIsOpen);

    const fetchOptions = async (inputValue) => {
      let _result = [];
      if (isConcatField && typeof concatModelName == "string") {
        var filters = {};

        if (aditional) {
          Object.keys(aditional).forEach((key) => {
            filters[key] = aditional[key];
          });
        }

        if (inputValue !== "") {
          if (!isNaN(inputValue)) {
            if (concatModelName === "cliente") {
              filters["pk"] = inputValue;
            } else if (concatModelName === "fechadura") {
              filters["referencia__icontains"] = inputValue;
            } else {
              filters["id"] = inputValue;
            }
          } else {
            let nomeCampo = "nome";
            if (concatModelName === "conta_banc") {
              nomeCampo = "banco__nome";
            } else if (concatModelName === "fechadura") {
              nomeCampo = "referencia";
            } else if (
              [
                "centro_custo",
                "plano_conta",
                "tipo_cliente",
                "plano_contratado",
                "regra_trib",
                "grupo_trib",
                "cond_pag",
                "cfop",
                "ramo_atividade",
                "percurso_mdfe",
                "rodape",
                "batente",
                "bobina",
                "borracha",
                "caixa_trilho",
                "cantoneira",
                "cola",
                "espacador",
                "fita_borda",
              ].includes(concatModelName)
            ) {
              nomeCampo = "descricao";
            }

            filters[`${nomeCampo}__icontains`] = inputValue;
          }
        }
        const ret = await ComboBoxService.fetchOptions(
          concatModelName,
          filters
        );
        setLoadedOptions(true);
        _result = ret;
      } else {
        _result = options;
      }
      return _result;
    };

    const clearValue = () => {
      selectRef.current.clearValue();
    };

    const setValue = (value) => {
      selectRef.current.setValue(value);
    };

    const setValueByID = useCallback(async (id) => {
      let i = 0;
      let checked = false;
      while (!checked && i < 10) {
        const options = getOptions();
        if (options.length > 0) {
          setValue(options.find((v) => v.value === id));
          checked = true;
        }
        i++;
        await (async () => setTimeout(() => {}, 75))();
      }
    }, []);

    const getOptions = () => {
      if (!selectRef?.current) return [];
      return selectRef.current.props.options;
    };

    const setFocus = () => {
      selectRef.current.focus();
    };

    useImperativeHandle(ref, () => ({
      clearValue: () => clearValue(),
      setValue: (v) => setValue(v),
      getOptions: () => getOptions(),
      setValueByID: (id) => setValueByID(id),
      getValue: () => selectedOption,
      setFocus: () => setFocus(),
    }));

    const __onChange = (selected, target) => {
      setSelectOption(selected);
      if (onChange) {
        onChange(
          isMulti ? selected : selected?.value ?? null,
          selected,
          target
        );
      }
    };

    // Quando o value é zero, não é atribuida a opção no Select, pois zero é falsy
    // Esta função abre uma exceção quando o concatModelName for colaborador
    const checkValueUpdate = (v) => {
      if (concatModelName === "colaborador") {
        return v === 0 ? true : v;
      } else {
        return v;
      }
    };

    useEffect(() => {
      if ([null, undefined].includes(value)) {
        clearValue();
      } else {
        if (
          checkValueUpdate(value) &&
          (loadedOptions || typeof defaultOptions !== Array)
        ) {
          (async () => setTimeout(() => setValueByID(value), 1000))();
        }
      }
    }, [setValueByID, value, loadedOptions]);

    useEffect(() => {
      if (disabled && clearOnDisable) {
        clearValue();
      }
    }, [disabled, clearOnDisable]);

    const setSelectedByShortcut = async (s) => {
      const ids = getOptions().map((e) => e.value);

      if (!ids.includes(s)) {
        const newValues = await fetchOptions(s);
        if (newValues?.length > 0) {
          selectRef.current.props.options.push(newValues[0]);
        }
      }

      setValueByID(s);
    };

    const onKeyDownInternal = (e) => {
      if (e.key === "Enter" && isMulti) return;
      handleFocus(e);
    };

    const recarregarOpcoes = async () => {
      if (selectRef.current && concatModelName) {
        const ret = await fetchOptions("");
        selectRef.current.props.options.splice(
          0,
          selectRef.current.props.options.length
        );
        selectRef.current.props.options.push(...ret);
      }
    };

    useEffect(() => {
      recarregarOpcoes();
    }, [concatModelName]);

    return (
      <>
        {selectedOption?.foto_base64 && (
          <Modal isOpen={modalIsOpen} toggle={toggleModal} centered>
            <ModalHeader toggle={toggleModal}>{label}</ModalHeader>
            <ModalBody style={{ justifyContent: "center", display: "flex" }}>
              <img src={selectedOption.foto_base64} height={275} />
            </ModalBody>
          </Modal>
        )}
        <Col md={md} className={className}>
          <FormGroup onKeyDown={onKeyDownInternal}>
            <Label for={`react-select-${internalId}-input`}>{label}</Label>
            <div style={{ display: "flex", width: "100%" }}>
              <AsyncSelect
                key={JSON.stringify(aditional)}
                placeholder={placeholder}
                className="react-select-container"
                classNamePrefix="react-select"
                loadOptions={isConcatField ? fetchOptions : loadOptions}
                name={name}
                isSearchable={isSearchable}
                isClearable={isClearable}
                isMulti={isMulti}
                defaultOptions={defaultOptions}
                onChange={__onChange}
                ref={selectRef}
                isDisabled={disabled}
                autoFocus={autoFocus}
                noOptionsMessage={() =>
                  isConcatField ? "Digite Algo" : "Sem Dados"
                }
                loadingMessage={() => "Carregando..."}
                isLoading={
                  typeof defaultOptions === Boolean &&
                  defaultOptions &&
                  !loadedOptions
                }
                tabOrder={tabOrder}
                onBlur={onBlur}
                instanceId={internalId}
                components={
                  isConcatField ? { Option: ComboBoxOptionWithPhoto } : null
                }
              />
              {!hideShortcut && isConcatField && !disabled && (
                <ConcatShortcut
                  concatModelName={concatModelName}
                  setSelected={setSelectedByShortcut}
                />
              )}
              {selectedOption?.foto_base64 && (
                <>
                  <HiPhoto
                    size={28}
                    style={{
                      display: "flex",
                      marginLeft: "7px",
                      cursor: "pointer",
                    }}
                    onClick={toggleModal}
                    id={`view-photo-${internalId}`}
                  />
                  <UncontrolledTooltip target={`view-photo-${internalId}`}>
                    Visualizar Imagem
                  </UncontrolledTooltip>
                </>
              )}
            </div>
          </FormGroup>
        </Col>
      </>
    );
  }
);

export default AsyncComboBox;
