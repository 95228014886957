import React, { useState, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { Row } from "reactstrap";
import {
  AsyncComboBox,
  ComboBox,
  FormButton,
  FormCheckbox,
  IntegerInput,
  NumberInput,
} from "../../../../../components";
import { apiGet, apiPost } from "../../../../../api";
import { Table } from "../../../../../components/Table";
import { formatNumber } from "../../../../../coreUtils";
import { tiposMaterial } from "../../../../../utils/materiais";

const espessurasFolha = [
  { label: "36mm", value: "36" },
  { label: "41mm", value: "41" },
];

const tiposFecha = [
  { label: "Interna", value: "INTER" },
  { label: "Externa", value: "EXTER" },
  { label: "Banheiro", value: "BANHE" },
  { label: "Rolete", value: "ROLET" },
  { label: "Asa de Avião", value: "ASAAV" },
  { label: "Concha", value: "CONCH" },
  { label: "Fechdura Aux.", value: "FEAUX" },
];

const IncluirFolhaPortaNegativoForm = ({
  abrirCab,
  idPedidoCab,
  buscarItensPedido,
  params,
}) => {
  // Dados componente inserido
  const [idComponente, setIdComponente] = useState(null);
  const [descricaoComponente, setDescricaoComponente] = useState(null);
  const [vlrUnitComponente, setVlrUnitComponente] = useState(0);
  const [quantidadeComponente, setQuantidadeComponente] = useState(1);
  const [alturaFolhaPronta, setAlturaFolhaPronta] = useState(null);
  const [larguraFolhaPronta, setLarguraFolhaPronta] = useState(null);
  const [usinarParaFechadura, setUsinarParaFechadura] = useState(false);
  const [tamanhoCaixaFecha, setTamanhoCaixaFecha] = useState(null);
  const [tipoFechadura, setTipoFechadura] = useState(null);
  const [idFitaBorda, setIdFitaBorda] = useState(null);
  const [negativo, setNegativo] = useState(null);

  // Filtros
  const [espessuraFolha, setEspessuraFolha] = useState(
    espessurasFolha[0].value
  );
  const [idCor, setIdCor] = useState(null);
  const [tipoMaterial, setTipoMaterial] = useState(tiposMaterial[0].value);
  const [larguraFolha, setLarguraFolha] = useState(null);
  const [alturaFolha, setAlturaFolha] = useState(null);

  const [largurasFolhas, setLargurasFolhas] = useState([]);
  const [alturasFolhas, setAlturasFolhas] = useState([]);
  const [tamanhosCaixaFecha, setTamanhosCaixaFecha] = useState([]);
  const [folhas, setFolhas] = useState([]);

  const [loadingIncluirComponente, setLoadingIncluirComponente] =
    useState(false);

  const columnsGrid = [
    {
      dataField: "descricao_material",
      text: "Material",
      align: "left",
      headerAlign: "left",
      sort: true,
    },
    {
      dataField: "id_cor__descricao",
      text: "Cor",
      align: "left",
      headerAlign: "left",
      sort: true,
    },
    {
      dataField: "altura",
      text: "Altura",
      align: "right",
      headerAlign: "right",
      formatter: (c) => formatNumber(c, true, 0),
      sort: true,
    },
    {
      dataField: "largura",
      text: "Largura",
      align: "right",
      headerAlign: "right",
      formatter: (c) => formatNumber(c, true, 0),
      sort: true,
    },
    {
      dataField: "espessura",
      text: "Espessura",
      align: "right",
      headerAlign: "right",
      formatter: (c) => formatNumber(c, true, 0),
      sort: true,
    },
    {
      dataField: "vlr_preco",
      text: "Preço",
      align: "right",
      headerAlign: "right",
      formatter: (c) => formatNumber(c, true, 2),
    },
  ];

  const limparIncluirNovoComponente = () => {
    setIdComponente(null);
    setDescricaoComponente(null);
    setVlrUnitComponente(0);
    setQuantidadeComponente(1);
    setTipoMaterial(tiposMaterial[0].value);
    setIdCor(null);
    setEspessuraFolha(null);
    setAlturaFolha(null);
    setLarguraFolha(null);
    setAlturaFolhaPronta(null);
    setLarguraFolhaPronta(null);
    setUsinarParaFechadura(false);
    setTamanhoCaixaFecha(null);
    setTipoFechadura(null);
    setIdFitaBorda(null);
    setNegativo(null);
  };

  const incluirComponente = async () => {
    setLoadingIncluirComponente(true);
    try {
      let idPedidoVincularItem;
      if (idPedidoCab == null) {
        const retAbrirCab = await abrirCab();
        if (retAbrirCab) {
          idPedidoVincularItem = retAbrirCab;
        } else {
          return;
        }
      } else {
        idPedidoVincularItem = idPedidoCab;
      }

      const payload = {
        id_pedido: idPedidoVincularItem,
        tipo_componente: "FOLH",
        id_componente: idComponente,
        descricao_componente: descricaoComponente ?? "",
        quantidade: quantidadeComponente,
        vlr_item: vlrUnitComponente,
        vlr_total: quantidadeComponente * vlrUnitComponente,
        obs: "",
        aux_folha: {
          usinar_para_fechadura: usinarParaFechadura,
          tamanho_caixa_fecha: tamanhoCaixaFecha,
          tipo_fecha: tipoFechadura,
          id_negativo: negativo?.value ?? null,
          id_fita_borda: idFitaBorda,
          altura_pronta: alturaFolhaPronta,
          largura_pronta: larguraFolhaPronta,
          tem_recorte: false,
        },
      };

      if (await apiPost("pedido/componentes/incluir/", payload)) {
        limparIncluirNovoComponente();
        buscarItensPedido(idPedidoVincularItem);
      }
    } finally {
      setLoadingIncluirComponente(false);
    }
  };

  const buscarFolhas = useCallback(async () => {
    if (tipoMaterial != null && espessuraFolha && idCor != null) {
      const ret = await apiGet("/cadastro/folha_porta/listar/", {
        tipo_material: tipoMaterial,
        espessura: espessuraFolha,
        id_cor: idCor,
        mostrar: "CNG",
        altura: alturaFolha,
        largura: larguraFolha,
      });
      setFolhas(ret ?? []);
    } else {
      setFolhas([]);
      setVlrUnitComponente(0);
      setIdComponente(null);
    }
  }, [tipoMaterial, espessuraFolha, idCor, alturaFolha, larguraFolha]);

  useEffect(() => {
    buscarFolhas();
  }, [buscarFolhas]);

  const buscarFiltros = async () => {
    const retLarguras = await apiGet("/cadastro/folha_porta/listar_larguras/");
    setLargurasFolhas(retLarguras ?? []);

    const retAlturas = await apiGet("/cadastro/folha_porta/listar_alturas/");
    setAlturasFolhas(retAlturas ?? []);

    const retTamanhosCaixa = await apiGet(
      "/cadastro/fechadura/listar_tamanhos_caixa/",
      { tipo_fechadura: tipoFechadura ?? undefined }
    );
    setTamanhosCaixaFecha(
      (retTamanhosCaixa ?? []).map((e) => ({
        label: String(parseInt(e.tam_caixa)),
        value: parseInt(e.tam_caixa),
      }))
    );
  };

  useEffect(() => {
    buscarFiltros();
  }, []);

  useEffect(() => {
    if (!usinarParaFechadura) {
      setTamanhoCaixaFecha(null);
      setTipoFechadura(null);
    }
  }, [usinarParaFechadura]);

  const custoBarraReforco = parseFloat(params?.preco_barra_ferro_est_folha);

  return (
    <>
      <Row>
        <AsyncComboBox
          label="Cor"
          isConcatField
          concatModelName={"cor_acabamento"}
          defaultOptions
          onChange={(_, selected) => {
            setIdCor(selected?.value);
          }}
          md={2}
          isClearable
          value={idCor}
          isSearchable={false}
        />
        <ComboBox
          md={3}
          label="Tipo de Material"
          options={tiposMaterial}
          value={tipoMaterial}
          onChange={setTipoMaterial}
          isSearchable={false}
        />
        <ComboBox
          md={2}
          label="Espessura da Folha"
          options={espessurasFolha}
          value={espessuraFolha}
          onChange={setEspessuraFolha}
          isSearchable={false}
        />
        <ComboBox
          md={2}
          label="Altura da Folha"
          options={alturasFolhas}
          value={alturaFolha}
          onChange={setAlturaFolha}
          isSearchable
          isClearable
        />
        <ComboBox
          md={2}
          label="Largura da Folha"
          options={largurasFolhas}
          value={larguraFolha}
          onChange={setLarguraFolha}
          isSearchable
          isClearable
        />
      </Row>
      <div className="mt-4 mb-4">
        <Table
          columns={columnsGrid}
          data={folhas}
          paginated={false}
          pageSize={15}
          onSelect={(v, checked, row) => {
            if (checked) {
              setIdComponente(v);
              setVlrUnitComponente(parseFloat(row.vlr_preco));
            } else {
              setIdComponente(null);
              setVlrUnitComponente(0);
            }
          }}
        />
      </div>
      <Row>
        <IntegerInput
          md={2}
          label="Altura da Folha Pronta"
          value={alturaFolhaPronta}
          onChange={setAlturaFolhaPronta}
          hint={
            custoBarraReforco > 0
              ? `Folhas com altura superior a 2300mm (2,30m) terão adicional de R$ ${formatNumber(
                  custoBarraReforco,
                  true,
                  2
                )} para reforço da estrutura`
              : null
          }
        />
        <IntegerInput
          md={2}
          label="Largura da Folha Pronta"
          value={larguraFolhaPronta}
          onChange={setLarguraFolhaPronta}
          hint={
            custoBarraReforco > 0
              ? `Folhas com largura superior a 1100mm (1,10m) terão adicional de R$ ${formatNumber(
                  custoBarraReforco,
                  true,
                  2
                )} para reforço da estrutura`
              : null
          }
        />
        <FormCheckbox
          md={2}
          label="Usinar para fechadura"
          onChange={() => setUsinarParaFechadura(!usinarParaFechadura)}
          checked={usinarParaFechadura}
          padded
        />
        {usinarParaFechadura && (
          <>
            <ComboBox
              md={2}
              label="Tamanho da Caixa"
              options={tamanhosCaixaFecha}
              value={tamanhoCaixaFecha}
              onChange={setTamanhoCaixaFecha}
            />
            <ComboBox
              md={2}
              label="Tipo de Fechadura"
              options={tiposFecha}
              value={tipoFechadura}
              onChange={setTipoFechadura}
            />
          </>
        )}
      </Row>
      <Row>
        <AsyncComboBox
          label="Fita de Borda"
          isConcatField
          concatModelName="fita_borda"
          defaultOptions
          onChange={(_, fitaBorda) => setIdFitaBorda(fitaBorda)}
          md={2}
          isClearable
        />
        <AsyncComboBox
          label="Negativo"
          isConcatField
          concatModelName="negativo"
          defaultOptions
          onChange={(_, negativo) => setNegativo(negativo)}
          value={negativo?.value}
          md={2}
        />
      </Row>
      <Row>
        <IntegerInput
          md={2}
          label="Quantidade"
          onChange={setQuantidadeComponente}
          value={quantidadeComponente}
        />
        <NumberInput
          md={2}
          label="Valor Unitário"
          disabled
          value={vlrUnitComponente}
        />
        <NumberInput
          md={2}
          label="Total do Item"
          disabled
          value={(quantidadeComponente ?? 0) * (vlrUnitComponente ?? 0)}
        />
        <FormButton
          md="auto"
          loading={loadingIncluirComponente}
          onClick={incluirComponente}
          color="info"
        >
          Incluir Componente
        </FormButton>
      </Row>
    </>
  );
};

IncluirFolhaPortaNegativoForm.propTypes = {
  abrirCab: PropTypes.func.isRequired,
  idPedidoCab: PropTypes.number.isRequired,
  buscarItensPedido: PropTypes.func.isRequired,
};

export default IncluirFolhaPortaNegativoForm;
