import React from "react";
import { Document, Image, StyleSheet, Text, View } from "@react-pdf/renderer";
import {
  A4Page,
  ReportDataListMultiRow,
  ReportFooter,
} from "../../../../components/pdf";
import { formatNumber, formatValueFromAPI } from "../../../../coreUtils";
import moment from "moment";

const styles = StyleSheet.create({
  text: {
    fontSize: "8",
    fontFamily: "Open Sans",
  },
  textBold: {
    fontSize: "8",
    fontFamily: "Open Sans Bold",
  },
  headerWrapper: {
    flexDirection: "row",
    marginBottom: 5,
    borderBottom: "0.5px solid #000",
    paddingBottom: "5px",
  },
  nomeEmpresa: { fontFamily: "Open Sans Bold", fontSize: 9 },
  logo: {
    maxHeight: "100%",
    maxWidth: 75,
    objectFit: "contain",
    alignSelf: "center",
    borderRight: "0.5px solid #000",
    padding: "10 10",
    marginRight: "10",
  },
  headerInfoEmpresa: { fontFamily: "Open Sans", fontSize: 8 },
  headerRow: {
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
  },
  total: {
    fontSize: "10",
    fontFamily: "Open Sans Bold",
    width: "100%",
    display: "flex",
  },
});

const columns1 = [
  {
    col: 0.4,
    dataField: "item",
    text: "Item",
    align: "center",
  },
  {
    col: 1.6,
    dataField: "descricao_material",
    text: "Material",
    align: "left",
  },
  {
    col: 2.3,
    dataField: "descricao",
    text: "Nome da Porta",
    align: "left",
  },
  {
    col: 1.7,
    dataField: "vao_largura",
    text: "Vão",
    align: "center",
    formatter: (c, row) =>
      `L: ${formatNumber(c, true, 2, true)} | ` +
      `A: ${formatNumber(row.vao_altura, true, 2, true)} | ` +
      `E: ${formatNumber(row.vao_espessura, true, 2, true)}`,
  },
  {
    col: 1.2,
    dataField: "fol_largura_pronta",
    text: "Folha Pronta",
    align: "center",
    formatter: (c, row) =>
      `L: ${formatNumber(c, true, 2, true)} | ` +
      `A: ${formatNumber(row.fol_altura_pronta, true, 2, true)}`,
  },
  {
    col: 1.5,
    dataField: "marco",
    text: "Marco",
    align: "left",
  },
  {
    col: 0.7,
    dataField: "modo_abertura",
    text: "Abertura",
    align: "left",
  },
  {
    col: 0.6,
    dataField: "lado_abertura",
    text: "Lado",
    align: "left",
  },
  {
    col: 2.2,
    dataField: "nome_negativo",
    text: "Cor - Negativo",
    align: "left",
    formatter: (_, row) =>
      row.nome_negativo
        ? `${row.nome_cor_acabamento} - ${row.nome_negativo}`
        : row.nome_cor_acabamento,
  },
];

const columns2 = [
  {
    col: 2,
    dataField: "vista",
    text: "",
    align: "left",
    formatter: (c) => (
      <>
        <Text style={styles.textBold}>Vista: </Text>
        <Text style={styles.text}>{c}</Text>
      </>
    ),
  },
  {
    col: 5,
    dataField: "fechadura",
    text: "",
    align: "left",
    formatter: (c) => (
      <>
        <Text style={styles.textBold}>Fechadura: </Text>
        <Text style={styles.text}>{c}</Text>
      </>
    ),
  },
  {
    col: 1,
    dataField: "tem_usinagem",
    text: "",
    align: "left",
    formatter: (c) => (
      <>
        <Text style={styles.textBold}>Usinagem: </Text>
        <Text style={styles.text}>{c ? "Sim" : "Não"}</Text>
      </>
    ),
  },
  {
    col: 1,
    dataField: "tem_montagem",
    text: "",
    align: "left",
    formatter: (c) => (
      <>
        <Text style={styles.textBold}>Montagem: </Text>
        <Text style={styles.text}>{c ? "Sim" : "Não"}</Text>
      </>
    ),
  },
  {
    col: 3,
    dataField: "vlr_total",
    text: "",
    align: "right",
    formatter: (c) => (
      <>
        <Text style={styles.textBold}>Vlr. Total: </Text>
        <Text style={styles.text}>{formatNumber(c, true, 2)}</Text>
      </>
    ),
  },
];

const columns3 = [
  {
    col: 12,
    dataField: "usinagens_especiais",
    text: "",
    align: "left",
    formatter: (c) => (
      <>
        <Text style={styles.textBold}>Itens Especiais: </Text>
        <Text style={styles.text}>{c?.map((e) => e.nome).join(", ")}</Text>
      </>
    ),
  },
];

export const PedidoPDF = ({ data }) => {
  const dataAgora = moment(new Date());
  const impressao = `Impresso em ${dataAgora.format(
    "DD/MM/YYYY"
  )} às ${dataAgora.format("HH:mm")}`;

  return (
    <Document>
      <A4Page orientation="landscape">
        <View style={styles.headerWrapper}>
          {data.logo_empresa && data.logo_empresa !== "" && (
            <Image style={styles.logo} src={data.logo_empresa} />
          )}
          <View style={{ width: "100%" }}>
            <View style={styles.headerRow}>
              <Text style={styles.nomeEmpresa}>{data.nome_empresa}</Text>
            </View>
            <Text style={styles.headerInfoEmpresa}>{data.cidade_empresa}</Text>
            <Text style={styles.headerInfoEmpresa}>
              Fone: {data.telefone_empresa}
            </Text>
            <Text style={styles.headerInfoEmpresa}>
              Pedido {data.nro_pedido} - {data.descricao_ped}
            </Text>
            <Text style={styles.headerInfoEmpresa}>
              Cliente:{" "}
              {data.id_cliente
                ? formatValueFromAPI(data.nome_cliente, data.id_cliente)
                : data.nome_cliente}
            </Text>
            <View style={styles.headerRow}>
              <Text style={styles.headerInfoEmpresa}>
                Entrega: {data.tem_entrega ? "Sim" : "Não"}
              </Text>
              <Text style={styles.headerInfoEmpresa}>{impressao}</Text>
            </View>
          </View>
        </View>
        <ReportDataListMultiRow
          data={data.itens}
          columns={[columns1, columns2, columns3]}
          stripped
          rowStyle={{ paddingBottom: "5px" }}
          headerStyle={{ backgroundColor: "#DDD" }}
        />
        <View
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row",
            width: "100%",
          }}
        >
          <Text style={styles.total}>
            Quantidade de Kits: {formatNumber(data.qtd_itens, true, 0)}
          </Text>
          <Text style={{ ...styles.total, textAlign: "center" }}>
            Frete: {formatNumber(data.vlr_frete, true, 2)}
          </Text>
          <Text style={{ ...styles.total, textAlign: "right" }}>
            Total Geral do Pedido: {formatNumber(data.vlr_total, true, 2)}
          </Text>
        </View>
        <ReportFooter reportNumber="0001_1" />
      </A4Page>
    </Document>
  );
};
